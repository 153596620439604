<template>
  <div class="first">
    <div class="BBg" :style="{ height: isheight }" @click="outwuye"></div>
    <div class="t1 mt10 bgwhite">
      <div class="block flex_column">
        <!-- <div class="title f-center p10">任务看板</div> -->
        <div class="flex_between bbe9s">
          <h3 class="f-left ptb10 title">任务看板</h3>
          <div>
            <span
              class="f13 mt10 Shrink"
              @click="Shrink(0)"
              style="color: #aba9a9"
              >{{ isindex[0].msg }}</span
            >
          </div>
        </div>
        <div
          class="flex_between mt20 bbe9s pb10"
          :style="{ display: isindex[0].tt == 't1' ? 'none' : 'flex' }"
        >
          <div
            class="flex_column txt_center"
            @click="chakan(false, 'mingxi')"
            style="cursor: pointer"
          >
            <p class="f16">今日任务总量</p>
            <p class="f16">{{ Headdata.count }}</p>
          </div>
          <!-- ？？？？？？？？？？？？？？？？？？？？？？？ -->
          <div
            class="flex_column txt_center"
            @click="chakan(false, 'waitDetails')"
          >
            <p class="f16">今日待处理</p>
            <p class="f16">{{ Headdata.count1 }}</p>
          </div>
          <div
            class="flex_column txt_center"
            @click="chakan(false, 'finishDetails')"
          >
            <p class="f16">今日已完成</p>
            <p class="f16">{{ Headdata.count2 }}</p>
          </div>
          <div
            class="flex_column txt_center"
            @click="chakan(false, 'urgentDetails')"
          >
            <p class="f16">今日紧急任务</p>
            <p class="f16">{{ Headdata.count3 }}</p>
          </div>
          <div
            class="flex_column txt_center"
            @click="chakan(false, 'dealVisitDetails')"
          >
            <p class="f16">待处理回访任务</p>
            <p class="f16">{{ Headdata.count4 }}</p>
          </div>
          <div
            class="flex_column txt_center"
            @click="chakan(false, 'hangupDetails')"
          >
            <p class="f16">挂起中的任务</p>
            <p class="f16">{{ Headdata.count5 }}</p>
          </div>
          <el-button
            type="primary"
            style="width: 60px; height: 30px"
            @click="getDataAgain"
            >刷新</el-button
          >
        </div>
        <div>
          <span
            class="f13 mt10 Shrink"
            @click="Shrink(1)"
            style="color: #aba9a9"
            >{{ isindex[1].msg }}</span
          >
        </div>
      </div>
    </div>
    <!-- 任务查找与筛选 -->
    <div class="mt10">
      <div class="block">
        <!-- <div class="title f-left p10">任务查找与筛选</div> -->
        <div :style="{ display: isindex[1].tt == 't1' ? 'none' : '' }">
          <div class="flex_warp pb20" style="overflow: inherit !important">
            <!-- 订单时间 -->
            <div class="blocks2 flex_items flex_start">
              <span class="mr10">订单时间</span>
              <el-date-picker
                v-model="time1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 256px"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
            <!-- 城市筛选 -->
            <div class="blocks2 flex_start flex_items">
              <span class="mr10">城市筛选</span>
              <el-select
                v-model="citykind"
                placeholder="选择城市"
                style="width: 127px"
                clearable
                @change="getwhere(citykind)"
              >
                <!-- @clear="clearCity()" -->
                <el-option
                  v-for="(item, index) in citys"
                  :key="index"
                  :label="item.city"
                  :value="item.city_id"
                ></el-option>
              </el-select>
            </div>
            <!-- 物业 -->
            <div
              v-if="citykind != ''"
              class="blocks2 flex_start flex_items pos"
            >
              <span class="mr10">选择物业</span>
              <el-input
                v-model="wuyeVal"
                placeholder="请选择物业"
                @focus="getwuye"
                style="width: 170px"
                :clearable="true"
              ></el-input>
              <ul
                v-if="wuyekuang && NewItems.length != 0"
                class="abs wuyekuang"
              >
                <li
                  v-for="(item, index) in NewItems"
                  :key="index"
                  :value="item.username"
                  v-text="item.username"
                  @click="chosewuye(index)"
                ></li>
              </ul>
              <p
                class="zanwu"
                v-if="NewItems.length == 0 && nowuye"
                @click="chosezanwu"
              >
                --暂无数据--
              </p>
            </div>
            <!-- 订单状态 -->
            <div class="blocks2 flex_start flex_items">
              <span class="mr10">订单状态</span>
              <el-select
                v-model="orderzhuang"
                placeholder="选择订单状态"
                style="width: 138px"
                :clearable="true"
              >
                <el-option
                  v-for="item in orderzhuangs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- 是否加微信 -->
            <div class="blocks2 flex_start flex_items">
              <span class="mr10">是否添加微信</span>
              <el-select
                v-model="wechat"
                placeholder="微信添加状态"
                style="width: 138px"
                :clearable="true"
              >
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </div>
            <!-- 任务类型 -->
            <div class="blocks2 flex_start flex_items">
              <span class="mr10">任务类型</span>
              <el-select
                v-model="taskid"
                placeholder="选择任务类型"
                style="width: 159px"
                :clearable="true"
              >
                <el-option
                  v-for="item in tasks"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <!-- 处理客服 -->
            <div class="blocks2 flex_start flex_items">
              <span class="mr10">录入客服</span>
              <el-select
                v-model="client"
                placeholder="选择录入客服"
                :clearable="true"
                style="width: 146px"
              >
                <el-option
                  v-for="(item, index) in clients"
                  :key="index"
                  :label="item.username"
                  :value="item.admin_id"
                ></el-option>
              </el-select>
            </div>
            <!-- 接单人 -->
            <div v-if="citykind != ''" class="blocks2 flex_items flex_start">
              <span class="mr10">接单人员</span>
              <el-select
                class="mr10"
                filterable
                v-model="shopper"
                collapse-tags
                placeholder="选择接单人员"
                :clearable="true"
              >
                <el-option
                  v-for="item in shoppers"
                  :key="item.user_id"
                  :label="item.usernamestorename"
                  :value="item.usernamestorename"
                ></el-option>
              </el-select>
            </div>
            <!-- 筛选订单 -->
            <div class="blocks2 flex_items">
              <el-input
                placeholder="请输入内容"
                v-model="chosecon"
                class="input-with-select"
              >
                <el-select
                  v-model="selectkind"
                  slot="prepend"
                  placeholder="请选择类别"
                  style="width: 120px"
                >
                  <el-option label="联系人" value="contacts"></el-option>
                  <el-option label="联系方式" value="telephone"></el-option>
                  <el-option label="订单号" value="order_no"></el-option>
                  <el-option label="维修地址" value="addres"></el-option>
                </el-select>
              </el-input>
            </div>
            <!-- 任务状态 -->
            <div class="blocks2 flex_start flex_items">
              <span class="mr10">任务状态</span>
              <el-select
                v-model="label"
                placeholder="选择任务状态"
                style="width: 159px"
                :clearable="true"
              >
                <el-option
                  v-for="item in tasksZhuangs"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- 筛选 -->
            <div class="blocks2 flex_items">
              <el-button
                type="primary"
                style="margin-right: 30px"
                @click="shaixuan"
                >筛选</el-button
              >
              <el-button type="warning" @click="Popupbox('add')"
                >录入订单</el-button
              >
            </div>
          </div>
        </div>
        <!-- <div>
          <span class="f13 mt10 Shrink" @click="Shrink(2)" style="color:#aba9a9">{{isindex[2].msg}}</span>
        </div>-->
      </div>
    </div>
    <!-- 任务列表 -->
    <div class="t1">
      <div class="block flex_column">
        <!-- <div class="title f-left p10">任务列表</div> -->
        <div class="mt10">
          <el-table :data="tableData" border v-loading="loading2">
            <el-table-column prop="cc_id" label="序号"></el-table-column>
            <el-table-column
              prop="manage_time"
              label="任务生成时间"
            ></el-table-column>
            <el-table-column prop="order_no" label="订单号"></el-table-column>
            <el-table-column label="任务类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 2">上门跟进任务</span>
                <span v-if="scope.row.type == 3">签约跟进任务</span>
                <span v-if="scope.row.type == 4">施工跟进任务</span>
                <span v-if="scope.row.type == 8">取消挽回任务</span>
                <span v-if="scope.row.type == 9">退单挽回任务</span>
                <span v-if="scope.row.type == 7">完工回访任务</span>
                <span v-if="scope.row.type == 20 || scope.row.type == 0"
                  >订单指派任务</span
                >
                <span v-if="scope.row.type == 10">订单投诉任务</span>
                <span v-if="scope.row.type == 11">售后任务</span>
                <!-- <span v-if="scope.row.type==20">待指派任务</span> -->
                <!-- <span v-if="scope.row.TaskStatus == 11">已完成任务</span> -->
                <!-- <span v-if="scope.row.TaskStatus == 98">未完成任务</span> -->
                <!-- <span v-if="scope.row.type == 2 && scope.row.TaskStatus == 0"
                  >上门跟进任务</span
                >
                <span v-if="scope.row.type == 3 && scope.row.TaskStatus == 0"
                  >签约跟进任务</span
                >
                <span v-if="scope.row.type == 4 && scope.row.TaskStatus == 0"
                  >施工跟进任务</span
                >
                <span v-if="scope.row.type == 8 && scope.row.TaskStatus == 0"
                  >取消挽回任务</span
                >
                <span v-if="scope.row.type == 9 && scope.row.TaskStatus == 0"
                  >退单挽回任务</span
                >
                <span v-if="scope.row.type == 7 && scope.row.TaskStatus == 0"
                  >完工回访任务</span
                >
                <span
                  v-if="
                    (scope.row.type == 20 || scope.row.type == 0) &&
                    scope.row.TaskStatus == 0
                  "
                  >订单指派任务</span
                >
                <span v-if="scope.row.type == 10 && scope.row.TaskStatus == 0"
                  >订单投诉任务</span
                > -->

                <!-- <span v-if="scope.row.TaskType==8&&scope.row.TaskStatus==0">已取消任务</span> -->
              </template>
            </el-table-column>
            <el-table-column prop="contacts" label="联系人"></el-table-column>
            <el-table-column
              prop="telephone"
              label="联系方式"
            ></el-table-column>
            <el-table-column prop="addres" label="维修地址"></el-table-column>
            <el-table-column prop="states" label="订单状态"></el-table-column>
            <el-table-column prop="store_name" label="店铺"></el-table-column>
            <el-table-column label="操作人员">
              <template slot-scope="scope">
                <span v-if="scope.row.admin_ids == ''">暂无操作人员</span>
                <span>{{ scope.row.admin_ids }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="TaskStatus" label="任务状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.TaskStatus == 1" type="success"
                  >已处理</el-tag
                >
                <el-tag v-else type="danger">未处理</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="添加微信">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.wechat"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="changewechat(scope.row.order_id, scope.row.wechat)"
                ></el-switch>
              </template>
            </el-table-column>

            <!-- 挂起操作 -->
            <el-table-column label="挂起操作" prop="hang_up">
              <template slot-scope="scope">
                <el-tag
                  type="danger"
                  v-if="scope.row.hang_up == 0"
                  @click="doGua(scope.row, 1)"
                  >挂起</el-tag
                >
                <el-tag
                  type="success"
                  v-if="scope.row.hang_up == 1"
                  @click="doGua(scope.row, 0)"
                  >取消挂起</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" min-width="100">
              <template slot-scope="scope">
                <!-- <el-button
                  v-if="scope.row.TaskStatus == 1"
                  type="info"
                  class="mt10 ml10"
                  >已完成</el-button
                > -->
                <!-- <el-button type="primary" @click="Popupbox('assign',scope.row)">处理任务测试</el-button> -->
                <el-button
                  class="mt10 ml10"
                  v-if="
                    (scope.row.type == 20 || scope.row.type == 0) &&
                    scope.row.TaskStatus == 0
                  "
                  type="primary"
                  @click="Popupbox('AssignOrder', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  class="mt10 ml10"
                  v-if="scope.row.type == 2 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('door', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  class="mt10 ml10"
                  v-if="scope.row.type == 3 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('signing', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  v-if="scope.row.type == 1 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('longrange', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  class="mt10 ml10"
                  v-if="scope.row.type == 4 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('building', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  class="mt10 ml10"
                  v-if="scope.row.type == 7 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('completed', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  class="mt10 ml10"
                  v-if="scope.row.type == 10 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('takecomplaints', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  class="mt10 ml10"
                  v-if="
                    (scope.row.type == 8 || scope.row.type == 9) &&
                    scope.row.TaskStatus == 0
                  "
                  type="primary"
                  @click="Popupbox('cancelrecall', scope.row)"
                  >处理任务</el-button
                >
                <el-button
                  class="mt10 ml10"
                  v-if="scope.row.type == 11 && scope.row.TaskStatus == 0"
                  type="primary"
                  @click="Popupbox('aftersales', scope.row)"
                  >处理任务</el-button
                >
                <!-- 查看订单详情 -->
                <el-button
                  type="warning"
                  @click="chakan(scope.row, 'detailes')"
                  class="mt10 ml10"
                  >查看订单</el-button
                >
                <!-- 转派 -->
                <el-button
                  v-if="scope.row.TaskStatus != 1"
                  type="success"
                  @click="zhuandan(scope.row, 'zhuandan')"
                  class="mt10 ml10"
                  >转派</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="flex_start mt20 flex_items">
            <span class="demonstration mr20">
              总共
              <span style="color: red">{{ count }}</span
              >条数据,当前 <span style="color: red">{{ tableData.length }}</span
              >条
            </span>
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage3"
              :page-size="20"
              :total="count"
              layout="prev, pager, next, jumper"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <div v-if="isdiag">
      <el-dialog
        :visible.sync="dialogTableVisible"
        append-to-body
        :close-on-click-modal="false"
        @open="diagopen()"
        @close="diagclose()"
      >
        <!-- <Add :is="dialogname == 'add'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></Add>
        <Door :is="dialogname == 'door'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></Door>
        <Signing :is="dialogname == 'signing'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></Signing>
        <Longrange :is="dialogname == 'longrange'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></Longrange>
        <Building :is="dialogname == 'building'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></Building>
        <Completed :is="dialogname == 'completed'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></Completed>
        <Takecomplaints :is="dialogname == 'takecomplaints'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></Takecomplaints>
        <Cancelrecall :is="dialogname == 'cancelrecall'" @refreshEvent="getDataAgain" :currentPage3.sync="currentPage3"></Cancelrecall>
        <AssignOrder :is="dialogname == 'AssignOrder'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></AssignOrder>
        <Detailes :is="dialogname == 'chakan'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></Detailes>
        <aftersales :is="dialogname == 'aftersales'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></aftersales>
        <works :is="dialogname=='mingxi'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></works>
        <waitDeal :is="dialogname=='waitDetails'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></waitDeal>
        <finishDeal :is="dialogname=='finishDetails'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></finishDeal>
        <urgentDetails :is="dialogname=='urgentDetails'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></urgentDetails>
        <dealVisitDetails :is="dialogname=='dealVisitDetails'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></dealVisitDetails>
        <hangupDetails :is="dialogname=='hangupDetails'" @getDataAgain="getDataAgain" :currentPage3.sync="currentPage3"></hangupDetails> -->

        <component
          :is="dialogname"
          :currentPage3.sync="currentPage3"
          @getDataAgain="getDataAgain"
        ></component>
      </el-dialog>
    </div>
    <!-- 转派弹框 -->
    <!-- 转派 -->
    <div v-if="dialogTableVisible2" class="zhuanzhuan">
      <el-dialog :visible.sync="dialogTableVisible2">
        <div class="title f-left plr10 pb10 bbe9s">转派订单</div>
        <!-- 转派客服 -->
        <div class="flex_items mt10 pos">
          <span class="mr20" style="margin-left: 30%">转派客服</span>
          <el-select
            class="mr10"
            filterable
            v-model="kefu"
            collapse-tags
            placeholder="选择客服"
          >
            <el-option
              v-for="item in kefus"
              :key="item.admin_id"
              :label="item.username"
              :value="item.admin_id"
            ></el-option>
          </el-select>
          <el-button
            type="primary"
            style="margin: 10px auto"
            @click="tijiaozhuan"
            >转派任务</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import config from "@/util/config";
export default {
  data() {
    return {
      isdiag: false,
      loading: true,
      loading2: false,
      isheight: "",
      activeNames: ["1"],
      Headdata: {},
      isindex: [
        {
          tt: "-1",
          msg: "--收起--",
        },
        {
          tt: "-1",
          msg: "--收起--",
        },
      ],
      //  订单状态
      orderzhuangs: config.orderzhuangs,

      // 任务状态
      tasks: [],
      tasksZhuangs: [
        {
          value: " ",
          label: "全部",
        },
        {
          value: "0",
          label: "未处理",
        },
        {
          value: "1",
          label: "已处理",
        },
      ],
      TaskStatus: "",
      clients: [],
      time1: "",
      chosecon: "",
      selectkind: "",
      orderzhuang: "",
      client: "",
      clienton: "",
      citys: [],
      genjin: [],
      jiedan: "",
      citykind: "",
      taskid: "",
      //   物业
      wuyeVal: "",
      wuyeid: "",
      wuyekuang: false,
      nowuye: false,
      items: [],
      // 分页数据
      currentPage3: 1,
      tableData: [],
      count: 0,
      // 弹框
      dialogTableVisible: false,
      dialogname: "add",
      task: 0,
      shoppers: [],
      shopper: "",
      dialogTableVisible2: false,
      kefus: [],
      kefu: "",
      kefucc_id: "",
      wechat: "",
      label: "",
      shengid: "",
      details: {
        province_id: "",
        city_id: "",
        county_id: "",
      },
      childPage: 0,
    };
  },
  mounted() {
    this.getData();
    this.getcitys();
    this.gettasks();
    // if(this.currentPage3 == 1){
    this.getLists();
    // }
  },
  methods: {
    // 刷新重新获取数据
    getDataAgain(currentPage3) {
      if (currentPage3) {
        this.currentPage3 = currentPage3;
      }
      //console.log(this.currentPage3, "+++++++++++++++++++++++++++++");
      (this.time1 = ""),
        (this.citykind = ""),
        (this.wuyeVal = ""),
        (this.orderzhuang = ""),
        (this.wechat = ""),
        // this.taskid = "",
        (this.client = ""),
        (this.shopper = ""),
        (this.label = ""),
        // this.chosecon = "",
        this.util
          .get(this.HOST + "/Cc/Cc", {
            start_time: undefined,
            end_time: undefined,
            city_id: undefined,
            status: undefined,
            store_id: undefined,
            full: undefined,
            chen: undefined,
            contacts: undefined,
            admin_id: undefined,
            assignor: undefined,
            complaints: undefined,
            residential_id: undefined,
            wechat: undefined,
            page: this.currentPage3,
            complaints: this.taskid,
            admin_id: this.clienton,
            limit: 20,
            type: 0,
            types: 0,
          })
          .then((res) => {
            if (res.code == 200) {
              this.count = res.data.count;
              for (let i = 0; i < res.data.data.length; i++) {
                res.data.data[i].manage_time = this.util.timestampToTime(
                  res.data.data[i].manage_time
                );
              }
              // //console.log(res.data.data.manage_time+"!!!")
              this.tableData = res.data.data;
              for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].wechat == 0) {
                  this.tableData[i].wechat = false;
                } else {
                  this.tableData[i].wechat = true;
                }
              }
              this.loading2 = false;
              // this.getData()
            } else {
              this.loading2 = false;
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90,
              });
            }
          });
    },
    //执行挂起与取消挂起操作
    doGua(row, hangUp) {
      if (row.order_id == null) {
        this.$message({
          showClose: true,
          message: "订单编号不存在!",
          type: "error",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.util
        .get(this.HOST + "/Cc/HangUp", {
          cc_id: row.cc_id,
          hang_up: hangUp,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "挂起状态操作成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.shaixuan();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 抬头数据
    getData() {
      this.$loading(this.util.opload);
      this.util.get(this.HOST + "/Cc/DataLine", {}).then((res) => {
        if (res.code == 200) {
          this.Headdata = res.data;
          this.clienton = sessionStorage.getItem("admin");
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 城市
    getcitys() {
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.citys = res.data;
          // this.wuyes();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 筛选城市之后请求物业和店长
    getwhere(citykind) {
      //console.log(this.group_id, this.shopper);
      this.wuyeVal = "";
      this.shopper = "";
      this.order_id = "";
      // this.util
      // .get(this.HOST + "/Common/chanel", {
      // city_id: this.citykind,
      // })
      // .then((res) => {
      // if ( == 200) {
      // this.qudaos = res.data;
      if (citykind) {
        this.citys.map((item) => {
          if (citykind == item.city_id) {
            this.shengid = item.province_id;
          }
        });
        this.wuyes();
        this.shop("aa");
      }
      // }
      // });
    },
    // 获取物业
    wuyes() {
      this.util
        .get(this.HOST + "/Common/residential", {
          city_id: this.citykind,
          //uu: this.citykind,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.items = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 任务类型
    gettasks() {
      this.util.get(this.HOST + "/Common/service", {}).then((res) => {
        if (res.code == 200) {
          this.tasks = res.data;
          this.getclient();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 获取客服
    getclient() {
      this.util.get(this.HOST + "/Common/CustomerService", {}).then((res) => {
        if (res.code == 200) {
          this.clients = res.data;
          // this.shop();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 获取店长
    shop(aa) {
      this.shoppers = [];
      this.util
        .get(this.HOST + "/Common/user", {
          city_id: this.citykind,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
            // this.getLists();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 列表
    getLists(fen) {
      //console.log(this.currentPage3, "--------------------------------");
      if (fen) {
        this.$loading(this.util.opload);
      }
      this.util
        .get(this.HOST + "/Cc/Cc", {
          page: this.currentPage3,
          complaints: this.taskid,
          admin_id: this.clienton,
          limit: 20,
          type: 0,
          types: 0,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            for (let i = 0; i < res.data.data.length; i++) {
              res.data.data[i].manage_time = this.util.timestampToTime(
                res.data.data[i].manage_time
              );
            }
            this.tableData = res.data.data;
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].wechat == 0) {
                this.tableData[i].wechat = false;
              } else {
                this.tableData[i].wechat = true;
              }
            }
            this.$store.commit("settask", 0);
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 分页
    handleCurrentChange(val) {
      this.loading2 = true;
      let time1 = this.util.datatime(this.time1[0]);
      let time2 = this.util.datatime(this.time1[1]);
      if (time1 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time1 = "";
        time2 = "";
      }
      this.tableData = [];
      let admin_id = this.clienton;
      if (this.client != "") {
        admin_id = this.client;
      }
      this.util
        .get(this.HOST + "/Cc/Cc", {
          page: this.currentPage3,
          start_time: time1,
          end_time: time2,
          city_id: this.citykind,
          status: this.orderzhuang,
          store_id: this.store,
          full: this.Satisfied,
          limit: 20,
          chen: this.selectkind,
          contacts: this.chosecon,
          admin_id: admin_id,
          assignor: this.shopper,
          complaints: this.taskid,
          residential_id: this.wuyeVal,
          type: 0,
          types: 0,
          wechat: this.wechat,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.tableData = res.data.data;
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].wechat == 0) {
                this.tableData[i].wechat = false;
              } else {
                this.tableData[i].wechat = true;
              }
            }
            this.loading2 = false;
          } else {
            this.loading2 = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 收缩
    Shrink(num) {
      if (this.isindex[num].tt == "t1") {
        this.isindex[num].tt = "-1";
        this.isindex[num].msg = "--收起--";
      } else {
        this.isindex[num].tt = "t1";
        this.isindex[num].msg = "--展开--";
      }
    },
    // 弹框
    Popupbox(con, row) {
      this.wuyekuang = false;
      if (con == "assign") {
        sessionStorage.setItem("kind", "assign");
      }
      this.dialogname = con;
      if (row) {
        this.$store.commit("setOrder", row);
        // this.$store.commit("setcc", row);
      }
      this.$store.commit("setMessage", true);
    },
    diagopen() {
      this.$store.commit("setMessage", true);
    },
    diagclose() {
      this.$store.commit("setMessage", false);
    },
    getwuye(event) {
      this.wuyekuang = true;
      this.nowuye = true;
      this.isheight = this.util.gaodu() + "px";
    },
    outwuye() {
      this.wuyekuang = false;
      this.nowuye = false;
      this.isheight = "0px";
    },
    chosewuye(idx, event) {
      this.wuyeid = this.NewItems[idx].group_id;
      this.wuyeVal = this.NewItems[idx].username;
      this.wuyekuang = false;
      this.nowuye = false;
      this.isheight = "0px";
    },
    closewuye() {
      this.wuyekuang = false;
      this.isheight = "0px";
      this.nowuye = false;
    },
    chosezanwu() {
      this.nowuye = false;
    },
    shaixuan() {
      if (this.time1 == null) {
        this.time1 = "";
      }
      let time1 = this.util.datatime(this.time1[0]);
      let time2 = this.util.datatime(this.time1[1]);
      if (time1 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time1 = "";
        time2 = "";
      }

      this.loading2 = true;
      this.tableData = [];
      this.currentPage3 = 1;
      this.wuyekuang = false;
      let admin_id = this.clienton;
      if (this.client != "") {
        admin_id = this.client;
      }
      this.util
        .get(this.HOST + "/Cc/Cc", {
          start_time: time1,
          end_time: time2,
          city_id: this.citykind,
          status: this.orderzhuang,
          store_id: this.store,
          full: this.Satisfied,
          limit: 20,
          chen: this.selectkind,
          contacts: this.chosecon,
          admin_id: admin_id,
          assignor: this.shopper.split("_")[0],
          complaints: this.taskid,
          residential_id: this.wuyeVal,
          type: 0,
          page: 1,
          types: 0,
          wechat: this.wechat,
          label: this.label,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            for (let i = 0; i < res.data.data.length; i++) {
              res.data.data[i].manage_time = this.util.timestampToTime(
                res.data.data[i].manage_time
              );
            }
            this.tableData = res.data.data;
            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].wechat == 0) {
                this.tableData[i].wechat = false;
              } else {
                this.tableData[i].wechat = true;
              }
            }
            this.loading2 = false;
          } else {
            this.loading2 = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 查看订单
    chakan(row, con) {
      this.wuyekuang = false;
      this.dialogname = con;
      if (row) {
        this.$store.commit("setOrder", row);
        // //console.log(this.$store.state.order_id+'----------------------')
      }
      this.$store.commit("setMessage", true);
    },
    // 转派
    zhuandan(row, con) {
      this.util.get(this.HOST + "/User/user", {}).then((res) => {
        if (res.code == 200) {
          this.kefus = res.data;
          this.kefucc_id = row.cc_id;
          this.dialogTableVisible2 = true;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 添加微信操作
    changewechat(order_id, wechat) {
      let wechat2 = 0;
      if (wechat == true) {
        wechat2 = 1;
      }
      this.util
        .get(this.HOST + "/Order/SelectorSwitch", {
          order_id: order_id,
          wechat: wechat2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "开启成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },

    // 提交转派
    tijiaozhuan() {
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "任务转派中...",
      });
      this.util
        .get(this.HOST + "/Order/Transfers", {
          cc_id: this.kefucc_id,
          admin_id: this.kefu,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "任务转派成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("settask", 1);
              this.dialogTableVisible2 = false;
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
  },
  // 物业
  computed: {
    NewItems() {
      var _this = this;
      var NewItems = [];
      this.items.map(function (item) {
        if (item.username.search(_this.wuyeVal) != -1) {
          NewItems.push(item);
        }
      });
      return NewItems;
    },
    // 判断弹出框
    getStoreItem() {
      this.dialogTableVisible = this.$store.state.dialogTableVisible;
      this.isdiag = this.$store.state.dialogTableVisible;
    },
    // 判断处理成功
    getStoreTask() {
      if (this.$store.state.task == 1) {
        // this.tableData = [];
        // this.getLists("fen");
        // this.shaixuan()
      }
    },
    getworks() {
      this.works = this.$store.state.works;
    },
  },
  watch: {
    getStoreItem() {
      // //console.log(this.$store.state.dialogTableVisible, "121212");
    },
    getStoreTask() {
      // //console.log(this.$store.state.task, "121212");
    },
  },
  components: {
    Add: () => import("@/components/addorder.vue"),
    Door: () => import("@/components/door.vue"),
    Signing: () => import("@/components/signing.vue"),
    Longrange: () => import("@/components/Longrange.vue"),
    Building: () => import("@/components/building.vue"),
    Completed: () => import("@/components/Completed.vue"),
    Takecomplaints: () => import("@/components/takecomplaints.vue"),
    Cancelrecall: () => import("@/components/Cancelrecall.vue"),
    // Assign: assign,
    AssignOrder: () => import("@/components/AssignOrder.vue"),
    Detailes: () => import("@/components/detailes.vue"),
    aftersales: () => import("@/components/aftersales.vue"),
    works: () => import("@/components/worksdetail.vue"),
    waitDeal: () => import("@/components/waitDeal.vue"),
    finishDeal: () => import("@/components/finishDeal.vue"),
    urgentDetails: () => import("@/components/urgentDetails.vue"),
    dealVisitDetails: () => import("@/components/dealVisitDetails.vue"),
    hangupDetails: () => import("@/components/hangupDetails.vue"),
  },
};
</script>
<style scoped>
.first {
  margin: 0 auto;
  background: white;
  padding: 15px 0 0 30px;
  border-radius: 8px;
}
.el-main {
  padding: 10px;
}
.title {
  font-weight: 400;
  color: #1f2f3d;
  font-size: 18px;
  background: none;
  width: 93%;
}
p {
  margin: 0;
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.t2span {
  width: 64px;
}
.conplles {
  height: 76px;
}
.conplles2 {
  height: 0;
}
.Shrink {
  cursor: pointer;
  float: right;
  display: inline-block;
}
.el-input {
  width: auto;
}
/* 物业 */
.wuyekuang {
  top: 34px;
  left: 75px;
  width: 170px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
}
.wuyekuang li {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.wuyekuang li:hover {
  background-color: #f5f7fa;
}
li {
  list-style-type: none;
}
.zanwu {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 14px;
  top: 50px;
  left: 85px;
  width: 202px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
.el-dialog {
  width: 85%;
}
</style>
